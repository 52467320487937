<template>
  <div class="container-fluid mt--6 api-page">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 col-md-12 text-center mb-3">
            <el-input
              v-model="apiQuery.search"
              placeholder="Search Api"
              class="form-control-alternative"
            ></el-input>
          </div>
          <div class="col-sm-12 col-md-3 text-center mb-3">
            <el-date-picker
              v-model="apiQuery.start_date"
              type="date"
              placeholder="Start date"
              class="form-control-alternative"
            >
            </el-date-picker>
          </div>
          <div class="col-sm-12 col-md-3 text-center mb-3">
            <el-date-picker
              v-model="apiQuery.end_date"
              type="date"
              placeholder="End date"
              class="form-control-alternative"
            >
            </el-date-picker>
          </div>
          <div class="col-sm-12 col-md-3 text-center mb-3">
            <el-select
              v-model="apiQuery.method"
              placeholder="Currency"
              class="form-control-alternative"
              style="width: 100%"
            >
              <el-option label="All Method" value=""></el-option>
              <el-option label="ccfxNoMarkup" value="ccfxNoMarkup"></el-option>
              <el-option
                label="savepaymentschedule"
                value="savepaymentschedule"
              ></el-option>
              <el-option label="manualfx" value="manualfx"></el-option>
              <el-option label="autowallet" value="autowallet"></el-option>
              <el-option label="autofx" value="autofx"></el-option>
            </el-select>
          </div>
          <div class="col-sm-12 col-md-3 text-center">
            <el-button
              class="btn btn-primary"
              @click="getApiQuery()"
              v-loading="quering"
              :disabled="quering"
              >Query</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="card mb-3">
          <div class="card-header"></div>

          <div class="card-body">
            <el-table
              :data="dataTable"
              :loading="loading"
              border
              style="width: 100%"
              size="mini"
            >
              <el-table-column
                prop="id"
                label="ID"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="uid"
                label="UID"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="method"
                label="Method"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="req"
                label="Request"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="showMemo(scope.row.uid, scope.row.req)"
                    >MEMO</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="res"
                label="Respone"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag type="success" v-if="scope.row.res === '1'"
                    ><i class="el-icon-finished"></i> OK</el-tag
                  >
                  <el-tag type="danger" v-if="scope.row.res === '0'"
                    ><i class="el-icon-warning-outline"></i> Fails</el-tag
                  >
                  <el-button
                  v-if="scope.row.res !== '0' && scope.row.res !== '1'"
                    type="text"
                    @click="showMemo(scope.row.uid, scope.row.res)"
                    >MEMO</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="url"
                label="URL"
                width="250"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="created_date"
                label="Created"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="updated_date"
                label="Update"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="resend"
                label="Re-send"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="uid"
                label="Action"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    @click="onSubmitResend(scope.row.uid)"
                    >Re-send</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="Response" :visible.sync="dialogVisible" width="50%">
      <span>
        Ref. {{ memoUID }}
        <pre>
          <code id="show-memo">{{ memoRequest }}</code>
        </pre>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Utils from "@/utils";
import ManageApi from "@/api/manage";
export default {
  data() {
    return {
      quering: false,
      loading: false,
      dialogVisible: false,
      dataTable: [],
      memoUID: "",
      memoRequest: "",
      apiQuery: {
        search: "",
        start_date: Utils.getCurrentDate(),
        end_date: Utils.getCurrentDate(),
        method: "",
      },
    };
  },
  methods: {
    getApiQuery() {
      this.dataTable = [];
      this.loading = true;
      this.quering = true;
      this.apiQuery.start_date = Utils.dateFormat(this.apiQuery.start_date);
      this.apiQuery.end_date = Utils.dateFormat(this.apiQuery.end_date);
      ManageApi.apiSearch(this.apiQuery).then(({ result, data }) => {
        this.loading = false;
        this.quering = false;
        if (result) {
          this.dataTable = data;
        }
      });
    },
    IsJsonString(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return false;
      }
    },
    showMemo(uid, memo) {
      this.dialogVisible = true;
      var jsonParse = this.IsJsonString(memo);
      var codeRender = memo;
      if (jsonParse) {
        var inData = this.IsJsonString(jsonParse.data);
        jsonParse.data = inData;
        codeRender = JSON.stringify(jsonParse, null, 4);
      }
      this.memoUID = uid;
      this.memoRequest = codeRender;
    },
    onSubmitResend(uid) {
      this.$swal({
        title: "Resend API",
        html: `This will re-send api ${uid}. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((confirm) => {
        if (confirm.value) {
          const loading = this.$notifyloading("Sending...")
          ManageApi.apiResend({ uid: uid }).then(({ result, message }) => {
            loading.close()
            if (result) {
              this.$swal(
                `Message`,
                message,
                "success"
              ).then(() => {
                this.getApiQuery();
              });
            } else {
              this.$swal("Message", message, "error");
            }
          });
        } else {
          this.$message(`Send Api Canceled`);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.api-page {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
    min-width: 100% !important;
  }
}
</style>